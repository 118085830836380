<template>
  <el-drawer
    class="setUp-drawer"
    title="权限设置"
    :visible.sync="drawer"
    :append-to-body="true"
    direction="rtl"
    :before-close="handleClose"
  >
    <div class="drawer-container">
      <div class="setUp-tip">菜单权限</div>
      <div v-for="(item, index) in menuAll" :key="item.id" class="setUp-content">
        <el-checkbox :key="item.id" :value="item.checked" @change="(e) => checkAllChange(e, index)" >{{ item.name }}</el-checkbox>
        <div class="checkbox-item" v-for="(itemTwo, indexTwo) in item.children" :key="item.id + '-' + itemTwo.id">
          <el-checkbox :key="item.id + '-' + itemTwo.id" :value="itemTwo.checked" :title="itemTwo.remark" @change="(e) => checkChange(e, index, indexTwo)">{{ itemTwo.name }}</el-checkbox>
          <div class="checkbox-item" v-for="(itemThree, indexThree) in itemTwo.children" :key="item.id + '-' + itemTwo.id + '-' + itemThree.id">
            <el-checkbox :key="item.id + '-' + itemThree.id" :value="itemThree.checked" :title="itemThree.remark" @change="(e) => checkChange(e, index, indexTwo, indexThree)">{{ itemThree.name }}</el-checkbox>
          </div>
        </div>
      </div>
      <div class="setUp-btn">
        <el-button class="common-screen-btn" @click="handleClose()">取 消</el-button>
        <el-button :disabled="canEdit != '1'" class="common-screen-btn" type="primary" @click="() => submitMenu()" >确 定</el-button>
      </div>
    </div>
  </el-drawer>
</template>
<script>
import { getAllMenu, getRoleMenu, commitMenu } from "../../../service/manage.js";
export default {
  props: ["drawer", "canEdit"],
  data() {
    return {
      menuAll: [],
    };
  },
  created() {
    this.getAllMenu();
  },
  methods: {
    async submitMenu() {
      // 提交菜单权限
      let menuAll = [...this.menuAll];
      let roleId = this.roleId;
      let commitArr = [];
      menuAll.map((item) => {
        if (item.checked) {
          commitArr.push(item.id + "");
          if (item.children) {
            item.children.map((itemTwo) => {
              if (itemTwo.checked) {
                commitArr.push(itemTwo.id + "");
                if (itemTwo.children) {
                  itemTwo.children.map((itemThree) => {
                    if (itemThree.checked) {
                      commitArr.push(itemThree.id + "");
                      // commitArr.push(item.id + "-" + itemTwo.id);
                    }
                  });
                }
              }
            });
          }
        }
      });
      await commitMenu({ menuIds: commitArr, roleId: roleId });
      this.$message.success("设置成功");
      this.handleClose();
    },
    async getRoleMenu(id) {
      // 获取角色菜单
      this.roleId = id;
      let menuAll = [...this.menuAll];
      let resData = (await getRoleMenu({}, id)).data;
      this.menuAll = this.mapChecked(resData, menuAll);
      this.$emit("handleClose", true);
    },
    mapChecked(roleMenu, allMenu) {
      // 选中菜单数据处理
      allMenu.map((item) => {
        // 重置所有菜单
        item.checked = false;
        if (item.children) {
          item.children.map((itemTwo) => {
            itemTwo.checked = false;
            if (itemTwo.children) {
              itemTwo.children.map((itemThree) => {
                itemThree.checked = false
              })
            }
          });
        }
      });
      roleMenu.map((item) => {
        // 更改选中的值
        allMenu.map((itemTwo) => {
          if (item.name == itemTwo.name) {
            itemTwo.checked = true;
            if (item.children) {
              itemTwo.children = this.mapChecked(item.children, itemTwo.children);
            }
          }
        });
      });
      return allMenu;
    },
    async getAllMenu() {
      // 获取所有菜单权限
      let resData = (await getAllMenu()).data;
      this.menuAll = resData;
    },
    handleClose() {
      this.$emit("handleClose");
    },
    checkChange(e, index, indexTwo, indexThree) {
      // 选择checkbox处理数据
      let menuAll = [...this.menuAll];
      if (!indexThree && indexThree != 0) {
        if (menuAll[index].children) {
          menuAll[index].children[indexTwo].checked = e;
          menuAll[index].checked = false;
          menuAll[index].children.map((item) => {
            if (item.checked) {
              menuAll[index].checked = true;
            }
          });
          if (menuAll[index].children[indexTwo].children) {
              menuAll[index].children[indexTwo].children.map((item) => {
                item.checked = e
              })
            }
        }
      } else {
        if (menuAll[index].children[indexTwo].children) {
          menuAll[index].children[indexTwo].children[indexThree].checked = e;
          menuAll[index].checked = false;
          menuAll[index].children[indexTwo].checked = false;
          menuAll[index].children[indexTwo].children.map((item) => {
            if (item.checked) {
              menuAll[index].children[indexTwo].checked = true;
            }
          });
          menuAll[index].children.map((item) => {
            if (item.checked) {
              menuAll[index].checked = true;
            }
          });
        }
      }
      this.menuAll = menuAll;
      console.log(e, index, indexTwo);
    },
    checkAllChange(e, index) {
      // 选择checkboxAll处理数据
      let menuAll = [...this.menuAll];
      menuAll[index].checked = e;
      if (menuAll[index].children) {
        menuAll[index].children.map((item) => {
          item.checked = e;
          if (item.children) {
            item.children.map((itemTwo) => {
              itemTwo.checked = e
            })
          }
        });
      }
      this.menuAll = menuAll;
    },
  },
};
</script>
<style lang="less" scoped>
.setUp-drawer {
  /deep/.el-drawer__open .el-drawer.rtl {
    width: 38% !important;
  }
  .setUp-content {
    padding: 12px 56px;
  }
  .setUp-btn {
    background: #fff;
    z-index: 999;
    position: absolute;
    bottom: 0;
    text-align: right;
    width: 100%;
    height: 53px;
    padding: 10px 16px;
    box-sizing: border-box;
    border-top: 1px solid rgb(233, 233, 233);
  }
  .setUp-tip {
    margin-left: 24px;
    height: 37px;
    border-bottom: 1px solid #e8e8e8;
    padding-left: 16px;
    color: #000000d9;
  }
  .checkbox-item {
    margin: 12px 0 12px 36px;
  }
}
</style>
